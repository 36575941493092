import React, { useState, useEffect } from 'react';
import { Table, Button, Card, Col, Row } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';

//api
import api from '../../services/api';

//components
import {Layout, BtnEditar, BtnDeletar, Modal} from '../../components/Layout';
import {Input}  from '../../components/Form';

export default function List(props){
    
    const history = useHistory();
    
    const [modalActive, setModalActive] = useState(false);
    const [botao, setBotao] = useState({
        nome: "",
        link: "",
    });

    const [list, setList] = useState([]);

    useEffect(() => {
        
        async function getList(){
            let params = new URLSearchParams(props.location.search);
            let list = await api.get('/home/servicos?' + params.toString());
            setList(list.data);
        }
        getList();
        
        async function getBotao(){
            let result = await api.get('home');
            setBotao(result.data[0]);
        }
        getBotao();

    }, [props]);

    async function onChangeInput(e){
        botao[e.target.name] = e.target.value;
        setBotao({...botao});
    }

    async function onClickDelete(id){
        if( window.confirm('Deseja realmente excluir?') ){
            let response = await api.delete('/home/servicos/' + id);
            alert(response.data.message);
            history.push("/home");
        }
    }

    async function onSubmit(e)
    {
        e.preventDefault();

        try
        {
            let response = await api.post('/home/botao', botao);
            alert(response.data.message);
            if( response.data.error !== true ){
                setModalActive(false);
                history.push("/home");
            }
        
        } catch(err){
            alert("Ocorreu um erro ao salvar, tente novamente!");
            console.error(err);
        }
    }

    return (
        <Layout>
            
            <div className="filters">
                <h2>Home</h2>
                <Link to="/home/novo" className="btn btn-primary">Adicionar serviço</Link>
                <Button variant="secondary" onClick={(e) => setModalActive(true)}>Alterar botão da página</Button>
            </div>
            <hr />

            <Table className="tableList" hover>
                <thead>
                    <tr>
                        <th>Serviço</th>
                        <th>Link</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                {list.map(item => (
                    <tr key={item.id}>
                        <td>{item.titulo}</td>
                        <td>{item.link}</td>
                        <td className="action">
                            <BtnEditar path={`/home/${item.id}`} />
                        </td>
                        <td className="action">
                            <BtnDeletar onClick={() => onClickDelete(item.id)} />
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>

            <Modal modalActive={modalActive}>
                <form onSubmit={onSubmit}>
                    <Card>
                        <Card.Header><h3>Atualizar botão</h3></Card.Header><br/>
                        <Card.Body>
                            <Row>
                                <Input as={Col} label="Nome" name="nome" value={botao.nome} onChange={(e) => onChangeInput(e)} maxLength="60" />
                                <Input as={Col} label="Link" name="link" value={botao.link} onChange={(e) => onChangeInput(e)} />
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Button variant="secondary" className="mr-3" onClick={(e) => setModalActive(false)}>Cancelar</Button>
                            <Button variant="primary" type="submit">Salvar</Button>
                        </Card.Footer>
                    </Card>
                </form>
            </Modal>

        </Layout>
    );

};