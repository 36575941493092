import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Row, Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

//api
import api from '../../services/api';

//components
import {Input, TextareaEditor}  from '../../components/Form';
import {Layout} from '../../components/Layout';

export default function Item(props){

    const history = useHistory();
    const [descricao, setDescricao] = useState();
    const [item, setItem] = useState({
        ico: "",
        titulo: "",
        descricao: "",
        link: "",
    });
    
    useEffect(() => {
        if( props.match.params.id !== "novo" ) getItem(props.match.params.id);
    }, [props]);

    async function getItem(id){
        let result = await api.get('home/servicos/' + id);
        setDescricao(result.data.descricao);
        setItem(result.data);
    }

    async function onChangeInput(e){
        item[e.target.name] = e.target.value;
        setItem({...item});
    }

    const inputFile = useRef(null);
    const onClickFile = () => { inputFile.current.click() };
    async function onChangeFile(e){
        try
        {
            const formData = new FormData(); 
            formData.append("file", e.target.files[0], e.target.files[0].name);
            let response = await api.post('/home/servicos/upload', formData);
            item['ico'] = response.data.file;
            setItem({...item});

        } catch(err){
            alert("Ocorreu um erro ao salvar, tente novamente!");
            console.error(err);
        }
    }

    async function onSubmit(e)
    {
        e.preventDefault();

        try
        {
            item.descricao = descricao;
            console.log(item);

            let response;
            if( item.id ){
                response = await api.put('/home/servicos/' + item.id, item);
            } else {
                response = await api.post('/home/servicos', item);
            }
            alert(response.data.message);
            if( response.data.error !== true ) history.push('/home');
        
        } catch(err){
            alert("Ocorreu um erro ao salvar, tente novamente!");
            console.error(err);
        }
    }
    
    return (
        <Layout>

            <h2>{item.id ? 'Editar' : 'Novo'} serviço</h2>
            <hr />

            <form onSubmit={onSubmit}>

                <Card>
                    <Card.Header><h3>Dados</h3></Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm="3">
                                <div className="imageChange" onClick={onClickFile}>
                                    <span style={{backgroundImage: `url('${item.ico}')`}}></span>
                                    <button type="button">[Alterar ícone]</button>
                                    <input type="file" ref={inputFile} onChange={(e) => onChangeFile(e)} /> 
                                </div>
                            </Col>
                            <Col>
                            <Input label="Título" name="titulo" value={item.titulo} onChange={(e) => onChangeInput(e)} />
                                <Input label="Link" name="link" value={item.link} onChange={(e) => onChangeInput(e)} />
                            </Col>
                        </Row>

                        <TextareaEditor label="Descrição" value={descricao} onChange={(e, editor) => setDescricao(editor.getData())} />
                    </Card.Body>
                </Card>

                <div className="actions">
                    <Link to="/home" className="btn btn-secondary">Cancelar</Link>
                    <Button variant="primary" type="submit">Salvar</Button>
                </div>

            </form>

        </Layout>
    );

};