import React, { useEffect } from 'react';
import {Layout} from '../../components/Layout';

export default function Dashboard(props) {

    useEffect(() => {
    }, [props]);

    return (
        <Layout>
        </Layout>
    );

}