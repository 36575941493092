import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export function BtnNovo(props){

    // const [alloweds, setAlloweds] = useState([]);

    useEffect(() => {
        // setAlloweds(localStorage.getItem('routes'));
    }, [props]);

    return (
        <>
        {/* {alloweds.includes(props.path) && */}
            <Link to={props.path} className="btn btn-primary">Adicionar</Link>
        {/* } */}
        </>
    );

};