import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

//api
import api from '../../services/api';

//components
import {Input, Select}  from '../../components/Form';
import {Layout} from '../../components/Layout';

export default function Item(props){

    const history = useHistory();
    const [item, setItem] = useState({
        nome: "",
        // ordem: "",
        link: "",
    });
    
    useEffect(() => {
        if( props.match.params.id !== "novo" ) getItem(props.match.params.id);
    }, [props]);

    async function getItem(id){
        let result = await api.get('compliances/' + id);
        setItem(result.data);
    }

    async function onChangeInput(e){
        item[e.target.name] = e.target.value;
        setItem({...item});
    }

    async function onChangeInputUploads(e){
        try
        {
            const formData = new FormData(); 
            formData.append("file", e.target.files[0], e.target.files[0].name);
            let response = await api.post('/compliances/upload', formData);
            item.link = response.data.file;
            setItem({...item});

        } catch(err){
            alert("Ocorreu um erro ao salvar, tente novamente!");
            console.error(err);
        }
    }
    
    async function onClickDeleteUpload(){
        if( window.confirm('Deseja remover o arquivo?') ){
            item.link = null;
            setItem({...item});
        }
    }

    async function onSubmit(e)
    {
        e.preventDefault();

        try
        {
            let response;
            if( item.id ){
                response = await api.put('/compliances/' + item.id, item);
            } else {
                response = await api.post('/compliances', item);
            }
            alert(response.data.message);
            if( response.data.error !== true ) history.push('/compliances');
        
        } catch(err){
            alert("Ocorreu um erro ao salvar, tente novamente!");
            console.error(err);
        }
    }
    
    return (
        <Layout>

            <h2>{item.id ? 'Editar' : 'Novo'} compliance</h2>
            <hr />

            <form onSubmit={onSubmit}>

                <Card>

                    <Card.Header>
                        <h3>Dados</h3>
                    </Card.Header>

                    <Card.Body>
                        <Row>
                            <Input as={Col} label="Nome" name="nome" value={item.nome} onChange={(e) => onChangeInput(e)} />
                            {/* <Select as={Col} options={[[1,'Coluna 1'],[2,'Coluna 2'],[3,'Coluna 3']]} label="Posição do arquivo" name="ordem" value={item.ordem} onChange={(e) => onChangeInput(e)} /> */}
                        </Row>

                        Arquivo: 
                        { item.link && 
                        <>
                        <a className="btn btn-sm btn-secondary ml-1 mr-1" href={item.link} target="_blank" rel="noopener noreferrer">Ver arquivo</a>
                        <Button className="btn btn-sm btn-danger" onClick={() => onClickDeleteUpload()}>Remover</Button>
                        </>
                        }
                        <br/><br/>
                        <input type="file" onChange={(e) => onChangeInputUploads(e)} /> 

                    </Card.Body>
                    
                </Card>

                <div className="actions">
                    <Link to="/compliances" className="btn btn-secondary">Cancelar</Link>
                    <Button variant="primary" type="submit">Salvar</Button>
                </div>

            </form>

        </Layout>
    );

};