import React from 'react';

//icons
import { Icon } from '@iconify/react';
import trashAltF from '@iconify/icons-jam/trash-alt-f';

export function BtnDeletar(props){
    return (
        <button type="button" onClick={props.onClick}><Icon icon={trashAltF} /></button>
    );
};