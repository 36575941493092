import React, {Component} from 'react';
import Routes from './routes';

//css
import './assets/bootstrap.min.css';

export default class App extends Component {
    render() {
        return (
            <Routes />
        );
    }
}