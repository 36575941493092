import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";

//pages
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Denuncias from './pages/Denuncias';
import Contatos from './pages/Contatos';
import Newsletter from './pages/Newsletter';
import Perfil from './pages/Perfil';

import CompliancesList from './pages/Compliances/list';
import CompliancesItem from './pages/Compliances/item';

import FundosList from './pages/Fundos/list';
import FundosItem from './pages/Fundos/item';

import QuemSomosList from './pages/QuemSomos/list';
import QuemSomosItem from './pages/QuemSomos/item';

import HomeList from './pages/Home/list';
import HomeItem from './pages/Home/item';

import RelatoriosList from './pages/Relatorios/list';
import RelatoriosItem from './pages/Relatorios/item';

export default function Routes(props){
    
    function PrivateRoute({ children, ...rest })
    {

      return (

        <Route
          {...rest}
          render={({ location }) =>
            window.localStorage.getItem('token') ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location }
                }}
              />
            )
          }
        />

      )
    }

    return (
        <Router>
            <Switch>

                <Route path="/" exact component={Login}/>
                
                <PrivateRoute exact path="/dashboard">
                    <Route component={Dashboard} />
                </PrivateRoute>

                <PrivateRoute exact path="/denuncias">
                    <Route component={Denuncias} />
                </PrivateRoute>

                <PrivateRoute exact path="/contatos">
                    <Route component={Contatos} />
                </PrivateRoute>

                <PrivateRoute exact path="/perfil">
                    <Route component={Perfil} />
                </PrivateRoute>

                <PrivateRoute exact path="/newsletter">
                    <Route component={Newsletter} />
                </PrivateRoute>

                <PrivateRoute exact path="/compliances">
                    <Route component={CompliancesList} />
                </PrivateRoute>
                <PrivateRoute exact path="/compliances/:id">
                    <Route component={CompliancesItem} />
                </PrivateRoute>

                <PrivateRoute exact path="/fundos">
                    <Route component={FundosList} />
                </PrivateRoute>
                <PrivateRoute exact path="/fundos/:id">
                    <Route component={FundosItem} />
                </PrivateRoute>

                <PrivateRoute exact path="/quem-somos">
                    <Route component={QuemSomosList} />
                </PrivateRoute>
                <PrivateRoute exact path="/quem-somos/:id">
                    <Route component={QuemSomosItem} />
                </PrivateRoute>

                <PrivateRoute exact path="/home">
                    <Route component={HomeList} />
                </PrivateRoute>
                <PrivateRoute exact path="/home/:id">
                    <Route component={HomeItem} />
                </PrivateRoute>

                <PrivateRoute exact path="/relatorios">
                    <Route component={RelatoriosList} />
                </PrivateRoute>
                <PrivateRoute exact path="/relatorios/:id">
                    <Route component={RelatoriosItem} />
                </PrivateRoute>

                <Route path="*">
                  <h1>404</h1>
                </Route>

            </Switch>
        </Router>
    );
}