import React, { useState, useEffect } from 'react';
import { Table, Button, Card, Col } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';

//api
import api from '../../services/api';

//components
import {Layout, BtnEditar, BtnDeletar, Modal, DragAndDrop} from '../../components/Layout';
import {TextareaEditor}  from '../../components/Form';

//icons
import { Icon } from '@iconify/react';
import iconMove from '@iconify/icons-bi/arrows-move';

export default function List(props){
    
    const history = useHistory();
    const [modalActive, setModalActive] = useState(false);
    const [texto, setTexto] = useState();
    const [list, setList] = useState([]);

    useEffect(() => {
        
        async function getList(){
            let params = new URLSearchParams(props.location.search);
            let list = await api.get('quem-somos/equipe?' + params.toString());
            setList(list.data);
        }
        getList();
        
        async function getTexto(){
            let result = await api.get('quem-somos');
            setTexto(result.data[0].texto);
        }
        getTexto();

    }, [props]);

    async function onClickDelete(id){
        if( window.confirm('Deseja realmente excluir?') ){
            let response = await api.delete('quem-somos/equipe/' + id);
            alert(response.data.message);
            history.push("/quem-somos");
        }
    }

    async function onDrop(listUpdatedOrder)
    {
        setList(listUpdatedOrder);

        for( var [i,e] of listUpdatedOrder.entries() )
        {
            api.put('/quem-somos/equipe/' + e.id, {ordem: i+1});
        }
    }

    async function onSubmit(e)
    {
        e.preventDefault();

        try
        {
            let response = await api.post('/quem-somos/texto', {texto: texto});
            alert(response.data.message);
            if( response.data.error !== true ){
                setModalActive(false);
                history.push("/quem-somos");
            }
        
        } catch(err){
            alert("Ocorreu um erro ao salvar, tente novamente!");
            console.error(err);
        }
    }


    return (
        <Layout>

            <div className="filters">
                <h2>Quem Somos</h2>
                <Link to="/quem-somos/novo" className="btn btn-primary">Adicionar equipe</Link>
                <Button variant="secondary" onClick={(e) => setModalActive(true)}>Alterar texto da página</Button>
            </div>
            <hr />

            <Table className="tableList" hover>
                <thead>
                    <tr>
                        <th style={{width:"20px"}}></th>
                        <th>Nome</th>
                        <th>Cargo</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <DragAndDrop onDrop={onDrop} items={list}>
                    {list.map(item => (
                        <tr key={item.id}>
                            <td><Icon icon={iconMove} /></td>
                            <td>{item.nome}</td>
                            <td>{item.cargo}</td>
                            <td className="action">
                                <BtnEditar path={`/quem-somos/${item.id}`} />
                            </td>
                            <td className="action">
                                <BtnDeletar onClick={() => onClickDelete(item.id)} />
                            </td>
                        </tr>
                    ))}
                    </DragAndDrop>
                </tbody>
            </Table>

            <p style={{marginTop: "10px"}}>Clique e arraste para mudar a ordem.</p>
            
            <Modal modalActive={modalActive}>
                <form onSubmit={onSubmit}>
                    <Card>
                        <Card.Header><h3>Texto da página Quem Somos</h3></Card.Header><br/>
                        <TextareaEditor as={Col} value={texto} onChange={(e, editor) => setTexto(editor.getData())} maxLength="850" />
                        <Card.Footer>
                            <Button variant="secondary" className="mr-3" onClick={(e) => setModalActive(false)}>Cancelar</Button>
                            <Button variant="primary" type="submit">Salvar</Button>
                        </Card.Footer>
                    </Card>
                </form>
            </Modal>

        </Layout>
    );

};