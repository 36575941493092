import React, { useState, useEffect } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

//api
import api from '../../services/api';

//components
import {Input, Select}  from '../../components/Form';
import {Layout, Modal, BtnDeletar} from '../../components/Layout';


//icons
import { Icon } from '@iconify/react';
import iconEdit from '@iconify/icons-eva/edit-2-outline';

//jsons
const jsonCalendarioMeses = require('../../jsons/CalendarioMeses.json');

export default function Item(props){

    const history = useHistory();

    const [modalActive, setModalActive] = useState(false);

    const [meses, setMeses] = useState([]);

    const [item, setItem] = useState({
        ano: "",
        data: [],
    });

    const [itemData, setItemData] = useState({
        mes: "",
        link: "",
    });

    useEffect(() => {
        if( props.match.params.id !== "novo" ) getItem(props.match.params.id);
    }, [props]);

    async function getItem(id){
        let result = await api.get('relatorios/' + id);
        setMeses(result.data[0].data);
        setItem(result.data[0]);
    }

    async function onChangeInput(e){
        item[e.target.name] = e.target.value;
        setItem({...item});
    }

    async function onChangeInputData(e){
        itemData[e.target.name] = e.target.value;
        setItemData({...itemData});
    }

    async function onChangeInputUploads(e){
        try
        {
            const formData = new FormData(); 
            formData.append("file", e.target.files[0]);
            let response = await api.post('/relatorios/upload', formData);
            itemData.link = response.data.file;
            setItemData({...itemData});

        } catch(err){
            alert("Ocorreu um erro ao salvar, tente novamente!");
            console.error(err);
        }
    }

    async function onSubmit(e)
    {
        e.preventDefault();

        try
        {
            item.data = meses;
            console.log(item);

            let response;
            if( item.id ){
                response = await api.put('/relatorios/' + item.id, item);
            } else {
                response = await api.post('/relatorios', item);
            }
            alert(response.data.message);
            if( response.data.error !== true ) history.push('/relatorios');
        
        } catch(err){
            alert("Ocorreu um erro ao salvar, tente novamente!");
            console.error(err);
        }
    }

    async function itemDataAdd()
    {
        if( itemData['i'] ){
            meses[itemData['i']] = itemData;
        } else {
            meses.push(itemData);
        }
        
        setMeses(meses);

        setItemData({mes: "", link: ""});
        setModalActive(false);
    }

    async function itemDataChange(index)
    {
        meses[index]['i'] = index;
        setItemData(meses[index]);
        setModalActive(true);
    }

    async function itemDataDel(index)
    {
        let array = [];
        for( let i=0; i<meses.length; i++ ){
            console.log(meses[i])
            if( i !== index ) array.push(meses[i]);
        }
        setMeses(array);
    }
    
    return (
        <Layout>

            <h2>{item.id ? 'Editar' : 'Novo'} relatório</h2>
            <hr />

            <form onSubmit={onSubmit}>

                <Card>
                    <Card.Header><h3>Geral</h3></Card.Header>
                    <Card.Body>
                        <Input label="Ano" name="ano" value={item.ano} onChange={(e) => onChangeInput(e)} />
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Header>
                        <Button variant="secondary" className="float-right" onClick={() => setModalActive(true)}>Adicionar</Button>
                        <h3>Meses</h3>
                    </Card.Header>

                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Mês</th>
                                <th>Arquivo</th>
                                <th style={{width:'40px'}}></th>
                                <th style={{width:'40px'}}></th>
                            </tr>
                        </thead>

                        <tbody>
                        {meses.map((row, i) => (
                            <tr key={i}>
                                <td>{row.mes}</td>
                                <td>
                                    <a href={row.link} target="_blank" rel="noopener noreferrer">(Ver arquivo)</a>
                                </td>
                                <td className="action">
                                    <button type="button" onClick={() => itemDataChange(i)}><Icon icon={iconEdit} /></button>
                                </td>
                                <td className="action">
                                    <BtnDeletar onClick={() => itemDataDel(i)} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                </Card>

                <div className="actions">
                    <Link to="/relatorios" className="btn btn-secondary">Cancelar</Link>
                    <Button variant="primary" type="submit">Salvar</Button>
                </div>

            </form>

            <Modal modalActive={modalActive}>
                <Card>
                    <Card.Body>
                        <Select options={jsonCalendarioMeses} label="Mês" name="mes" value={itemData.mes} onChange={(e) => onChangeInputData(e)} />

                        Arquivo: 
                        { itemData.link && 
                        <a href={itemData.link} target="_blank" rel="noopener noreferrer">(Ver arquivo)</a>
                        }
                        <br/><br/>
                        <input type="file" onChange={(e) => onChangeInputUploads(e)} /> 
                    </Card.Body>
                    <Card.Footer>
                        <Button variant="secondary" className="mr-3" onClick={(e) => setModalActive(false)}>Cancelar</Button>
                        <Button variant="primary" onClick={itemDataAdd}>Salvar</Button>
                    </Card.Footer>
                </Card>
            </Modal>

        </Layout>
    );

};