import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

//api
import api from '../../services/api';

//components
import {Input, TextareaEditor}  from '../../components/Form';
import {Layout} from '../../components/Layout';

export default function Item(props){

    const history = useHistory();
    const [descricao, setDescricao] = useState();
    const [texto, setTexto] = useState();
    const [item, setItem] = useState({
        nome: "",
        descricao: "",
        texto: "",
        politica_investimento: {
            gestor: "",
            administrador: "",
            auditor: "",
            custodiante: "",
            publico_alvo: ""
        },
        movimentacoes: {
            investimento_inicial: "",
            movimentacao_minima: "",
            saldo_minimo: "",
            cota_da_aplicacao: "",
            cota_do_resgate: "",
            pagamento_do_resgate: ""
        },
        demais: {
            classificacao_anbina: "",
            taxa_de_administracao: "",
            taxa_de_performance: "",
            taxa_de_saida_antecipada: "",
            tributacao: ""
        },
        uploads: {
            relatorio_mensal: "",
            regulamento: "",
            documentos: "",
            relatorios_anteriores: ""
        }
    });
    
    useEffect(() => {
        if( props.match.params.id !== "novo" ) getItem(props.match.params.id);
    }, [props]);

    async function getItem(id){
        let result = await api.get('fundos/' + id);
        // console.log(result[0])
        setTexto(result.data[0].texto);
        setDescricao(result.data[0].descricao);
        setItem(result.data[0]);
    }

    async function onChangeInput(e){
        item[e.target.name] = e.target.value;
        setItem({...item});
    }

    async function onChangeInputPolitica(e){
        item.politica_investimento[e.target.name] = e.target.value;
        setItem({...item});
    }

    async function onChangeInputMovimentacoes(e){
        item.movimentacoes[e.target.name] = e.target.value;
        setItem({...item});
    }
    
    async function onChangeInputDemais(e){
        item.demais[e.target.name] = e.target.value;
        setItem({...item});
    }

    async function onChangeInputUploads(e, name){
        try
        {
            const formData = new FormData(); 
            formData.append("file", e.target.files[0], e.target.files[0].name);
            let response = await api.post('/fundos/upload', formData);
            item.uploads[name] = response.data.file;
            setItem({...item});

        } catch(err){
            alert("Ocorreu um erro ao salvar, tente novamente!");
            console.error(err);
        }
    }

    async function onClickDeleteUpload(name){
        if( window.confirm('Deseja remover o arquivo?') ){
            item.uploads[name] = null;
            setItem({...item});
        }
    }

    async function onSubmit(e)
    {
        e.preventDefault();

        try
        {
            item.descricao = descricao;
            item.texto = texto;
            console.log(item);

            let response;
            if( item.id ){
                response = await api.put('/fundos/' + item.id, item);
            } else {
                response = await api.post('/fundos', item);
            }
            alert(response.data.message);
            if( response.data.error !== true ) history.push('/fundos');
        
        } catch(err){
            alert("Ocorreu um erro ao salvar, tente novamente!");
            console.error(err);
        }
    }
    
    return (
        <Layout>

            <h2>{item.id ? 'Editar' : 'Novo'} fundo</h2>
            <hr />

            <form onSubmit={onSubmit}>

                <Card>
                    <Card.Header><h3>Dados</h3></Card.Header>
                    <Card.Body>
                        <Input label="Nome" name="nome" value={item.nome} onChange={(e) => onChangeInput(e)} maxLength="50" />
                        {/* <TextareaEditor label="Descrição" value={descricao} onChange={(e, editor) => setDescricao(editor.getData())} /> */}
                        <TextareaEditor label="Texto" value={texto} onChange={(e, editor) => setTexto(editor.getData())} maxLength="1400" />
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Header><h3>Informações gerais</h3></Card.Header>
                    <Card.Body>
                        <Row>
                            <Input as={Col} label="Gestor" name="gestor" value={item.politica_investimento.gestor} onChange={(e) => onChangeInputPolitica(e)} maxLength="50" />
                            <Input as={Col} label="Administrador" name="administrador" value={item.politica_investimento.administrador} onChange={(e) => onChangeInputPolitica(e)} maxLength="50" />
                        </Row>
                        <Row>
                            <Input as={Col} label="Auditor" name="auditor" value={item.politica_investimento.auditor} onChange={(e) => onChangeInputPolitica(e)} maxLength="50" />
                            <Input as={Col} label="Custodiante" name="custodiante" value={item.politica_investimento.custodiante} onChange={(e) => onChangeInputPolitica(e)} maxLength="50" />
                        </Row>
                        <Row>
                            <Input as={Col} label="Público Alvo" name="publico_alvo" value={item.politica_investimento.publico_alvo} onChange={(e) => onChangeInputPolitica(e)} maxLength="50" />
                        </Row>
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Header><h3>Movimentações</h3></Card.Header>
                    <Card.Body>
                        <Row>
                            <Input as={Col} label="Investimento inicial" name="investimento_inicial" value={item.movimentacoes.investimento_inicial} onChange={(e) => onChangeInputMovimentacoes(e)} maxLength="50" />
                            <Input as={Col} label="Movimentação mínima" name="movimentacao_minima" value={item.movimentacoes.movimentacao_minima} onChange={(e) => onChangeInputMovimentacoes(e)} maxLength="50" />
                            <Input as={Col} label="Saldo mínimo" name="saldo_minimo" value={item.movimentacoes.saldo_minimo} onChange={(e) => onChangeInputMovimentacoes(e)} maxLength="50" />
                        </Row>
                        <Row>
                            <Input as={Col} label="Cota da aplicação" name="cota_da_aplicacao" value={item.movimentacoes.cota_da_aplicacao} onChange={(e) => onChangeInputMovimentacoes(e)} maxLength="50" />
                            <Input as={Col} label="Cota do resgate" name="cota_do_resgate" value={item.movimentacoes.cota_do_resgate} onChange={(e) => onChangeInputMovimentacoes(e)} maxLength="50" />
                            <Input as={Col} label="Pagamento do resgate" name="pagamento_do_resgate" value={item.movimentacoes.pagamento_do_resgate} onChange={(e) => onChangeInputMovimentacoes(e)} maxLength="50" />
                        </Row>
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Header><h3>Demais</h3></Card.Header>
                    <Card.Body>
                        <Row>
                            <Input as={Col} label="Classificação anbina" name="classificacao_anbina" value={item.demais.classificacao_anbina} onChange={(e) => onChangeInputDemais(e)} maxLength="50" />
                            <Input as={Col} label="Taxa de administração" name="taxa_de_administracao" value={item.demais.taxa_de_administracao} onChange={(e) => onChangeInputDemais(e)} maxLength="50" />
                            <Input as={Col} label="Taxa de performance" name="taxa_de_performance" value={item.demais.taxa_de_performance} onChange={(e) => onChangeInputDemais(e)} maxLength="50" />
                        </Row>
                        <Row>
                            <Input as={Col} label="Taxa de saida antecipada" name="taxa_de_saida_antecipada" value={item.demais.taxa_de_saida_antecipada} onChange={(e) => onChangeInputDemais(e)} maxLength="50" />
                            <Input as={Col} label="Tributação" name="tributacao" value={item.demais.tributacao} onChange={(e) => onChangeInputDemais(e)} maxLength="50" />
                        </Row>
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Header><h3>Uploads</h3></Card.Header>
                    <Card.Body>
                        <Row>
                            <Col className="mb-3">
                                Relatório mensal: 
                                { item.uploads.relatorio_mensal && 
                                <>
                                <a className="btn btn-sm btn-secondary ml-1 mr-1" href={item.uploads.relatorio_mensal} target="_blank" rel="noopener noreferrer">Ver arquivo</a>
                                <Button className="btn btn-sm btn-danger" onClick={() => onClickDeleteUpload('relatorio_mensal')}>Remover</Button>
                                </>
                                }
                                <br/><br/>
                                <input type="file" onChange={(e) => onChangeInputUploads(e, 'relatorio_mensal')} /> 
                            </Col>
                            <Col className="mb-3">
                                Regulamento: 
                                { item.uploads.regulamento && 
                                <>
                                <a className="btn btn-sm btn-secondary ml-1 mr-1" href={item.uploads.regulamento} target="_blank" rel="noopener noreferrer">Ver arquivo</a>
                                <Button className="btn btn-sm btn-danger" onClick={() => onClickDeleteUpload('regulamento')}>Remover</Button>
                                </>
                                }
                                <br/><br/>
                                <input type="file" onChange={(e) => onChangeInputUploads(e, 'regulamento')} /> 
                            </Col>
                            <Col className="mb-3">
                                Documentos: 
                                { item.uploads.documentos && 
                                <>
                                <a className="btn btn-sm btn-secondary ml-1 mr-1" href={item.uploads.documentos} target="_blank" rel="noopener noreferrer">Ver arquivo</a>
                                <Button className="btn btn-sm btn-danger" onClick={() => onClickDeleteUpload('documentos')}>Remover</Button>
                                </>
                                }
                                <br/><br/>
                                <input type="file" onChange={(e) => onChangeInputUploads(e, 'documentos')} /> 
                            </Col>
                            <Col className="mb-3">
                                Relatórios anteriores: 
                                { item.uploads.relatorios_anteriores && 
                                <>
                                <a className="btn btn-sm btn-secondary ml-1 mr-1" href={item.uploads.relatorios_anteriores} target="_blank" rel="noopener noreferrer">Ver arquivo</a>
                                <Button className="btn btn-sm btn-danger" onClick={() => onClickDeleteUpload('relatorios_anteriores')}>Remover</Button>
                                </>
                                }
                                <br/><br/>
                                <input type="file" onChange={(e) => onChangeInputUploads(e, 'relatorios_anteriores')} /> 
                            </Col>
                            
                        </Row>
                    </Card.Body>
                </Card>

                <div className="actions">
                    <Link to="/fundos" className="btn btn-secondary">Cancelar</Link>
                    <Button variant="primary" type="submit">Salvar</Button>
                </div>

            </form>

        </Layout>
    );

};