import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

//api
import api from '../../services/api';

//components
import {Layout, BtnNovo, BtnEditar, BtnDeletar, DragAndDrop} from '../../components/Layout';
import {Input}  from '../../components/Form';

//icons
import { Icon } from '@iconify/react';
import iconMove from '@iconify/icons-bi/arrows-move';

export default function List(props){
    
    const history = useHistory();
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState([]);

    useEffect(() => {
        
        async function getList(){
            let params = new URLSearchParams(props.location.search);
            let list = await api.get('compliances?' + params.toString());
            setList(list.data);
        }
        getList();
        
    }, [props]);

    async function onSubmitSearch(e){
        e.preventDefault();
        history.push("/compliances?search=" + filter.search);
    }

    async function onClickDelete(id){
        if( window.confirm('Deseja realmente excluir?') ){
            let response = await api.delete('compliances/' + id);
            alert(response.data.message);
            history.push("/compliances");
        }
    }

    async function onDrop(listUpdatedOrder)
    {
        setList(listUpdatedOrder);

        for( var [i,e] of listUpdatedOrder.entries() )
        {
            api.put('/compliances/' + e.id, {ordem: i+1});
        }
    }

    return (
        <Layout>
            
            <div className="filters">
                <h2>Compliance</h2>
                <BtnNovo path="/compliances/novo" />

                <form onSubmit={onSubmitSearch}>
                    <Input placeholder="Buscar por..." onChange={(e) => setFilter({...{search: e.target.value}})} />
                    <Button type="submit" className="btn-secondary">Buscar</Button>
                </form>
            </div>

            <hr />

            <Table className="tableList" hover>
                <thead>
                    <tr>
                        <th style={{width:"20px"}}></th>
                        <th>Nome</th>
                        <th>Link</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <DragAndDrop onDrop={onDrop} items={list}>
                    {list.map(item => (
                        <tr key={item.id}>
                            <td><Icon icon={iconMove} /></td>
                            <td>{item.nome}</td>
                            <td>
                                <a href={item.link} rel="noopener noreferrer" target="_blank">Visualizar arquivo</a>
                            </td>
                            <td className="action">
                                <BtnEditar path={`/compliances/${item.id}`} />
                            </td>
                            <td className="action">
                                <BtnDeletar onClick={() => onClickDelete(item.id)} />
                            </td>
                        </tr>
                    ))}
                    </DragAndDrop>
                </tbody>
            </Table>

            <p style={{marginTop: "10px"}}>Clique e arraste para mudar a ordem.</p>

        </Layout>
    );

};