import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

//images
import logo from '../../../assets/logo.png';

//css
import './styles.scss';

//icons
import { Icon } from '@iconify/react';
// import iconBars from '@iconify/icons-fa/bars';
import iconDashboard from '@iconify/icons-carbon/dashboard';
import iconHome from '@iconify/icons-cil/monitor';
import iconQuemSomos from '@iconify/icons-bi/info-square';
import iconDenuncia from '@iconify/icons-gridicons/speaker';
import iconCompliances from '@iconify/icons-fa-solid/list-alt';
import iconFundos from '@iconify/icons-ri/organization-chart';
import iconContato from '@iconify/icons-ic/outline-contact-phone';
import iconNewsletter from '@iconify/icons-ic/outline-contact-mail';
import iconLogout from '@iconify/icons-icomoon-free/exit';
import iconWebsite from '@iconify/icons-dashicons/admin-site-alt3';
import iconPassword from '@iconify/icons-carbon/password';


export function Layout(props){

    const history = useHistory();
    // const [navHide, setNavhide] = useState('navHide');
    // const [navHide, setNavhide] = useState(null);

    useEffect(() => {
    }, [props]);

    // function navClick()
    // {
    //     if( navHide === 'navHide' )
    //     {
    //         setNavhide(null);
    //     } else {
    //         setNavhide('navHide');
    //     }
    // }

    function handleLogout(){
        localStorage.clear();
        history.push("/");
    }

    return (
        <>
        <div id="nav">
            
            {/* <div id="bar" onClick={(e) => navClick()}>
                <Icon icon={iconBars} />
            </div> */}
            
            <img src={logo} alt="Kilima" style={{width:'220px', margin:'20px 20px 0 20px', filter:'brightness(0) invert(1)'}} />
            
            {/* <Link to="/profile" id="user">
                <span className="image" style={{backgroundImage: `url('${profile.foto}')`}}></span>
                <span className="description">
                    <span className="name">{profile.nome}</span>
                    <span className="type">Usuário</span>
                </span>
            </Link> */}

            <nav className="pt-3">
                <Link to="/home" className={window.location.pathname === "/home" ? "active" : null}><Icon icon={iconHome} /> <span>Home</span></Link>
                <Link to="/quem-somos" className={window.location.pathname === "/quem-somos" ? "active" : null}><Icon icon={iconQuemSomos} /> <span>Quem Somos</span></Link>
                <Link to="/fundos" className={window.location.pathname === "/fundos" ? "active" : null}><Icon icon={iconFundos} /> <span>Fundos</span></Link>
                <Link to="/relatorios" className={window.location.pathname === "/relatorios" ? "active" : null}><Icon icon={iconDashboard} /> <span>Relatórios</span></Link>
                <Link to="/compliances" className={window.location.pathname === "/compliances" ? "active" : null}><Icon icon={iconCompliances} /> <span>Compliance</span></Link>
                {/* <Link to="/denuncias" className={window.location.pathname === "/denuncias" ? "active" : null}><Icon icon={iconDenuncia} /> <span>Denúncias</span></Link> */}
                <a href="https://app.compliasset.com/kilima-denuncia" rel="noopener noreferrer" target="_blank"><Icon icon={iconDenuncia} /> Acessar painel de denúncias</a>

                <Link to="/contatos" className={window.location.pathname === "/contatos" ? "active" : null}><Icon icon={iconContato} /> <span>Contatos</span></Link>
                <Link to="/newsletter" className={window.location.pathname === "/newsletter" ? "active" : null}><Icon icon={iconNewsletter} /> <span>Newsletter</span></Link>
                <Link to="/perfil" className={window.location.pathname === "/perfil" ? "active" : null}><Icon icon={iconPassword} /> <span>Alterar senha</span></Link>
                <a href="http://site.kilima.somocollab.com/" rel="noopener noreferrer" target="_blank"><Icon icon={iconWebsite} />Ir para o site</a>
                <button onClick={handleLogout}><Icon icon={iconLogout} /><span>Logout</span></button>
            </nav>
        </div>

        <div id="container" className={`p-3`}>
            {/* <img src={logo} className="logo" alt="ItGeo" /> */}
            {props.children}
        </div>
        </>
    );

};