import React, { useState } from 'react';
import api from '../../services/api';

import './styles.scss';

//images
import logo from '../../assets/logo.png';

export default function Login(props) {
    
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    async function handleLogin(e)
    {
        e.preventDefault();

        try
        {
            let response = await api.post('/account/login', {email: login, password: password});
            if( response.status !== 200 || response.error === true || !response.data.token ) throw response.data.message;
            localStorage.setItem('login', login);
            localStorage.setItem('token', response.data.token);
            window.location = "/dashboard";
        
        } catch(err){
            console.log(err);
            alert("Login ou senha inválida");
        }
    }

    return (

        <div id="pageLogin">

            <div className="banner">
            </div>

            <div className="form">

                <form onSubmit={handleLogin}>

                    <img src={logo} alt="Kilima" style={{width:'350px', marginBottom:'50px'}} />

                    <label>
                        <span>Login</span>
                        <input type="text" value={login} onChange={e => setLogin(e.target.value)} autoFocus />
                    </label>

                    <label>
                        <span>Senha</span>
                        <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                    </label>
                    
                    <button type="submit">Entrar</button>

                </form>

            </div>

        </div>
        
    );
}