import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

//api
import api from '../../services/api';

//components
import {Input}  from '../../components/Form';
import {Layout} from '../../components/Layout';

export default function Perfil(props){

    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {

        let email = localStorage.getItem('login');
        setEmail(email);

    }, [props]);

    async function onSubmit(e)
    {
        e.preventDefault();

        try
        {
            let item = {
                email: email,
                password: password
            };

            let response = await api.put('/account', item);
            alert(response.data.message);
            if( response.data.error !== true ) history.push('/');
        
        } catch(err){
            alert("Ocorreu um erro ao salvar, tente novamente!");
            console.error(err);
        }
    }
    
    return (
        <Layout>

            <h2>Editar perfil</h2>
            <hr />

            <form onSubmit={onSubmit}>

                <Card>
                    <Card.Header><h3>Dados</h3></Card.Header>
                    <Card.Body>
                        <Row>
                            <Input as={Col} label="Seu usuário" name="email" value={email} readOnly={true} maxLength="120" />
                            <Input as={Col} label="Nova Senha" name="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} maxLength="120" />
                        </Row>
                    </Card.Body>
                </Card>

                <div className="actions">
                    <Link to="/dashboard" className="btn btn-secondary">Cancelar</Link>
                    <Button variant="primary" type="submit">Salvar</Button>
                </div>

            </form>

        </Layout>
    );

};