import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Row, Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

//api
import api from '../../services/api';

//components
import {Input, TextareaEditor}  from '../../components/Form';
import {Layout} from '../../components/Layout';

export default function Item(props){

    const history = useHistory();
    const [texto, setTexto] = useState();
    const [item, setItem] = useState({
        nome: "",
        cargo: "",
        foto: "",
        texto: "",
        linkedin: "",
    });
    
    useEffect(() => {
        if( props.match.params.id !== "novo" ) getItem(props.match.params.id);
    }, [props]);

    async function getItem(id){
        let result = await api.get('quem-somos/equipe/' + id);
        setTexto(result.data.texto);
        setItem(result.data);
    }

    async function onChangeInput(e){
        item[e.target.name] = e.target.value;
        setItem({...item});
    }

    const inputFile = useRef(null);
    const onClickFile = () => { inputFile.current.click() };
    async function onChangeFile(e){
        try
        {
            const formData = new FormData(); 
            formData.append("file", e.target.files[0], e.target.files[0].name);
            let response = await api.post('/quem-somos/equipe/upload', formData);
            item['foto'] = response.data.file;
            setItem({...item});

        } catch(err){
            alert("Ocorreu um erro ao salvar, tente novamente!");
            console.error(err);
        }
    }

    async function onSubmit(e)
    {
        e.preventDefault();

        try
        {
            item.texto = texto;
            console.log(item);

            let response;
            if( item.id ){
                response = await api.put('/quem-somos/equipe/' + item.id, item);
            } else {
                response = await api.post('/quem-somos/equipe', item);
            }
            alert(response.data.message);
            if( response.data.error !== true ) history.push('/quem-somos');
        
        } catch(err){
            alert("Ocorreu um erro ao salvar, tente novamente!");
            console.error(err);
        }
    }
    
    return (
        <Layout>

            <h2>{item.id ? 'Editar' : 'Novo'} equipe</h2>
            <hr />

            <form onSubmit={onSubmit}>

                <Card>
                    <Card.Header><h3>Dados</h3></Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm="3">
                                <div className="imageChange" onClick={onClickFile}>
                                    <span style={{backgroundImage: `url('${item.foto}')`}}></span>
                                    <button type="button">[Alterar foto]</button>
                                    <input type="file" ref={inputFile} onChange={(e) => onChangeFile(e)} /> 
                                </div>
                            </Col>
                            <Col>
                                <Input label="Nome" name="nome" value={item.nome} onChange={(e) => onChangeInput(e)} maxLength="150" />
                                <Input label="Cargo" name="cargo" value={item.cargo} onChange={(e) => onChangeInput(e)} maxLength="150" />
                                <Input label="LinkedIn" name="linkedin" value={item.linkedin} onChange={(e) => onChangeInput(e)} maxLength="150" />
                            </Col>
                        </Row>
                        
                        <TextareaEditor label="Descrição" value={texto} onChange={(e, editor) => setTexto(editor.getData())} maxLength="1300" />
                    </Card.Body>
                </Card>

                <div className="actions">
                    <Link to="/quem-somos" className="btn btn-secondary">Cancelar</Link>
                    <Button variant="primary" type="submit">Salvar</Button>
                </div>

            </form>

        </Layout>
    );

};