import React from 'react';
import { Link } from 'react-router-dom';

//icons
import { Icon } from '@iconify/react';
import edit2Outline from '@iconify/icons-eva/edit-2-outline';

export function BtnEditar(props){

    return (
        <Link to={props.path}><Icon icon={edit2Outline} /></Link>
    );

};