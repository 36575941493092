import React, { useState, useEffect } from 'react';
import './styles.scss';

export function DragAndDrop(props){

    const initialDnDState = {
        draggedFrom: null,
        draggedTo: null,
        isDragging: false,
        originalOrder: [],
        updatedOrder: []
    }
    
    const DragToReorderList = () => {
        
        const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);
        
        // onDragStart fires when an element
        // starts being dragged
        const onDragStart = (event) => {
            const initialPosition = Number(event.currentTarget.dataset.position);
            
            setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: initialPosition,
            isDragging: true,
            originalOrder: props.items
            });
         
            // Note: this is only for Firefox.
            // Without it, the DnD won't work.
            // But we are not using it.
            event.dataTransfer.setData("text/html", '');
        }
        
        // onDragOver fires when an element being dragged
        // enters a droppable area.
        // In this case, any of the items on the list
        const onDragOver = (event) => {
         
            // in order for the onDrop
            // event to fire, we have
            // to cancel out this one
            event.preventDefault();

            let newList = dragAndDrop.originalOrder;

            // index of the item being dragged
            const draggedFrom = dragAndDrop.draggedFrom; 

            // index of the droppable area being hovered
            const draggedTo = Number(event.currentTarget.dataset.position); 

            const itemDragged = newList[draggedFrom];
            const remainingItems = newList.filter((item, index) => index !== draggedFrom);

            newList = [
            ...remainingItems.slice(0, draggedTo),
            itemDragged,
            ...remainingItems.slice(draggedTo)
            ];

            if (draggedTo !== dragAndDrop.draggedTo){
                setDragAndDrop({
                ...dragAndDrop,
                updatedOrder: newList,
                draggedTo: draggedTo
                })
            }
        }
        
        const onDrop = (event) => {

            // console.log("ORDER =>", dragAndDrop.updatedOrder)
            props.onDrop(dragAndDrop.updatedOrder);
            
            setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: null,
            draggedTo: null,
            isDragging: false
            });
        }
       
        const onDragLeave = () => {
            setDragAndDrop({
            ...dragAndDrop,
            draggedTo: null
            });
        }
        
        useEffect( ()=>{
            // console.log("Dragged From: ", dragAndDrop && dragAndDrop.draggedFrom);
            // console.log("Dropping Into: ", dragAndDrop && dragAndDrop.draggedTo);
        }, [dragAndDrop])
        
        return(
        <>
            {props.children.map( (item, index) => {
            return(
                <tr
                    key={index}
                    data-position={index}
                    draggable
                    onDragStart={onDragStart}
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                    onDragLeave={onDragLeave}
                    className={dragAndDrop && dragAndDrop.draggedTo=== Number(index) ? "dropArea dragAndDropItem" : "dragAndDropItem"}
                    >

                    {item.props.children}

                </tr>
            )
            })}
        </>
        )
    };

    return (
        <DragToReorderList />
    );

};