import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

//api
import api from '../../services/api';

//components
import {Layout, BtnDeletar} from '../../components/Layout';
import {Input}  from '../../components/Form';

export default function List(props){
    
    const history = useHistory();
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState([]);

    useEffect(() => {
        
        async function getList(){
            let params = new URLSearchParams(props.location.search);
            let list = await api.get('canal-denuncia?' + params.toString());
            setList(list.data);
        }
        getList();
        
    }, [props]);

    async function onSubmitSearch(e){
        e.preventDefault();
        history.push("/denuncias?search=" + filter.search);
    }

    async function onClickDelete(id){
        if( window.confirm('Deseja realmente excluir?') ){
            let response = await api.delete('canal-denuncia/' + id);
            alert(response.data.message);
            history.push("/denuncias");
        }
    }

    return (
        <Layout>
            
            <div className="filters">
                <h2>Canal Denúncia</h2>

                <form onSubmit={onSubmitSearch}>
                    <Input placeholder="Buscar por..." onChange={(e) => setFilter({...{search: e.target.value}})} />
                    <Button type="submit" className="btn-secondary">Buscar</Button>
                </form>
            </div>

            <hr />

            <Table className="tableList" hover>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>E-mail</th>
                        <th>Assunto</th>
                        <th>Mensagem</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                {list.map(item => (
                    <tr key={item.id}>
                        <td>{item.nome}</td>
                        <td>{item.email}</td>
                        <td>{item.assunto}</td>
                        <td>{item.mensagem}</td>
                        <td className="action">
                            <BtnDeletar onClick={() => onClickDelete(item.id)} />
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>

        </Layout>
    );

};